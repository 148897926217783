import axios from "axios";
import { toast } from "react-toastify";
import { handleErrorActions } from "../../shared/errorActions";

export const getUserInfo = async (token: string) => {
  const alternateDomain = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`;
  try {
    const response = await axios.get(alternateDomain, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    toast.error("Error");
    handleErrorActions(process.env.REACT_APP_AUTH0_DOMAIN as string)
    return null;
  }
};
